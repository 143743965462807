<template>
  <div class="home">
    <eden-page-header :title="'Issues Tracker'">
      <template slot="actions">
        <div class="page--head-actions">
          <el-button
            type="primary"
            @click="showIssueForm = true"
            v-if="
              allowAccessFor([
                'superadmin',
                'admin',
                'operations',
                'kitchen',
                'growth',
                'gardener',
                'gardener_pro',
              ])
            "
          >
            Add Issue
          </el-button>
          <el-button type="plain" @click="issuesCategories">
            Manage Issue Categories
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <eden-page-stats
      :title="'Overview'"
      :stats="stats"
      :loading="summary.loading"
      :show-periods="true"
      :periods="periods"
      @set-period="getIssuesSummary"
    />
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <el-row type="flex" justify="end" align="middle" :gutter="10">
          <el-col :span="5">
            <el-button
              type="plain"
              icon="eden-icon-upload"
              class="ml-10"
              :disabled="loading || !issues.length"
              @click="exportAction"
            >
              Export
            </el-button>
          </el-col>
          <!-- <el-col :span="18">
            <el-select
              v-model="departmentFilter"
              value-key="id"
              placeholder="Select the related department"
              @change="filter({ department: $event })"
            >
              <el-option
                v-for="department in departments"
                :key="department.id"
                :label="department.name"
                :value="department.name"
              >
              </el-option>
            </el-select>
          </el-col> -->
          <el-col :span="10" v-if="allowSelect">
            <issue-status
              @issue-resolved="getIssues"
              @selected-issues-updated="handleIssuesUpdate"
              :status.sync="issueObj.status"
              :selected-issues="selectedIssues"
              :is-bulk="true"
            />
          </el-col>
          <el-col :span="10">
            <el-select
              v-model="orderTypeFilter"
              value-key="id"
              placeholder="Select Order Type"
              @change="filterOrderTypes"
            >
              <el-option
                v-for="(type, idx) in orderTypes"
                :key="idx"
                :label="type.label"
                :value="type.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-select
              v-model="statusFilter"
              value-key="id"
              placeholder="Select Status"
              @change="filterIssueStatus"
            >
              <el-option
                v-for="(status, idx) in issueStatuses"
                :key="idx"
                :label="status.label"
                :value="status.value"
              >
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="5">
            <div class="is-flex is-justify-end">
              <issues-filter
                :disabled="loading"
                :clear="filterParams.clear"
                @filter="filter"
              />
            </div>
          </el-col>
        </el-row>
      </template>
    </eden-table-actions>

    <template>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <template v-if="issues.length">
          <el-table
            :data="issues"
            @select-all="setSelectedIssues"
            @selection-change="setSelectedIssues"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="allowRowSelect"
            ></el-table-column>
            <el-table-column width="200" min-height="89px">
              <template #header>
                <span>Issue</span>
              </template>
              <template v-slot="scope">
                <issue-title
                  :id="scope.row.id"
                  :title="scope.row.title"
                  :description="scope.row.description || ''"
                  :item="scope.row.order_specifics"
                  :comments="scope.row.comments.length"
                  :priority="scope.row.priority || ''"
                />
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template #header>
                <eden-table-column-header
                  :label="'Status'"
                  :property="'status'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'status')"
                />
              </template>
              <template v-slot="scope">
                <el-tag :type="setType(scope.row.status)">{{
                  formatText(scope.row.status.replace("-", " "))
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template #header>
                <eden-table-column-header
                  :label="'Service'"
                  :property="'service'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'service')"
                />
              </template>
              <template v-slot="scope">
                <eden-services-list
                  v-if="scope.row.issue_service"
                  :type="'single'"
                  :service="scope.row.issue_service"
                />
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template #header>
                <eden-table-column-header
                  :label="'Order Type'"
                  :property="'order_type'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'order_type')"
                />
              </template>
              <template v-slot="scope">
                <span class="font-sm">
                  {{
                    formatText(
                      scope.row.order_type === "one-time-order"
                        ? "One-time"
                        : "Subscription",
                    )
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column width="180">
              <template #header>
                <eden-table-column-header
                  :label="'Category'"
                  :property="'categories'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'categories')"
                />
              </template>
              <template v-slot="scope">
                <ul class="issue-categories font-sm">
                  <li
                    v-for="(category, idx) in scope.row.categories"
                    :key="idx"
                  >
                    {{ formatText(category.name) }}
                  </li>
                </ul>
              </template>
            </el-table-column>
            <el-table-column width="180">
              <template #header>
                <eden-table-column-header
                  :label="'Department'"
                  :property="'department'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'department')"
                />
              </template>
              <template v-slot="scope">
                <span class="font-sm"> {{ scope.row.department }}</span>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template #header>
                <eden-table-column-header
                  :label="'Customer'"
                  :property="'customer'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'customer')"
                />
              </template>
              <template v-slot="scope">
                <issue-customer
                  :type="'customer'"
                  :customers="
                    [scope.row.affected_user] == null
                      ? []
                      : [scope.row.affected_user]
                  "
                  :gardener="
                    scope.row.gardener == null ? '' : scope.row.gardener.name
                  "
                />
              </template>
            </el-table-column>
            <el-table-column width="180">
              <template #header>
                <eden-table-column-header
                  :label="'Date'"
                  :property="'date'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'date')"
                />
              </template>
              <template v-slot="scope">
                <span class="font-sm">
                  {{ formatDate(scope.row.created_at, "m do, y") }}</span
                >
              </template>
            </el-table-column>

            <!-- edit-starts -->
            <el-table-column min-width="180">
              <template #header>
                <eden-table-column-header
                  :label="'Resolution Time'"
                  :property="'resolution_time'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'resolution_time')"
                />
              </template>
              <template v-slot="scope">
                <ul
                  class="issue-categories font-sm"
                  style="list-style-type: none"
                >
                  <li
                    v-for="(category, idx) in scope.row.categories"
                    :key="idx"
                  >
                    {{ category.resolution_time }}
                    {{ formatText(category.resolution_unit) }}
                  </li>
                </ul>
              </template>
            </el-table-column>

            <!-- edit-ends -->
          </el-table>
          <eden-pagination
            v-if="issues.length && showPagination"
            :page-size="pagination.pageSize"
            :from="pagination.from"
            :to="pagination.to"
            :total="pagination.total"
            :current-page.sync="page"
          />
        </template>
        <eden-content-empty v-else :text="'No data'" />
      </template>
    </template>
    <issue-form :show.sync="showIssueForm" @success="getIssues" />
    <issues-export
      :show.sync="showExportIssues"
      :data-to-export="exportData"
      :stringed-filter="stringedParams"
    />
  </div>
</template>

<script>
// import { formatIssues } from "@/components/Feedback/Issues/issues.config";
import IssueTitle from "@/components/Feedback/Issues/Issue/Structure/IssueTitle";
import IssueCustomer from "@/components/Feedback/Issues/Issue/Structure/IssueCustomer";
import IssueForm from "@/components/Feedback/Issues/IssueForm";
import IssuesFilter from "@/components/Feedback/Issues/IssuesFilter";
import IssuesExport from "@/components/Feedback/Issues/IssuesExport";
import IssueStatus from "@/components/Feedback/Issues/Issue/Structure/IssueStatus";

import issues from "@/requests/feedback/issues";
import { setDepartments } from "@/components/Feedback/feedback.config";

export default {
  name: "Issues",
  components: {
    IssueForm,
    IssueCustomer,
    IssueTitle,
    IssuesFilter,
    IssuesExport,
    IssueStatus,
  },
  data() {
    return {
      loading: false,
      issueObj: {},
      selectedIssues: [],
      page: 1,
      issues: [],
      exportData: [],
      showPagination: true,
      showTransactionExport: false,
      showExportIssues: false,
      showIssueForm: false,
      stringedParams: "",
      sort: {
        property: "name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      departmentFilter: "",
      statusFilter: "All Issues",
      orderTypeFilter: "All Order Types",
      departments: setDepartments(),
      issueStatuses: [
        { label: "Open Issues", value: "ongoing" },
        { label: "Resolved Issues", value: "resolved" },
        { label: "All Issues", value: "" },
      ],
      orderTypes: [
        { label: "Subscription Orders", value: "subscription" },
        { label: "One-time Orders", value: "one-time-order" },
        { label: "All Order Types", value: "" },
      ],
      summary: {
        loading: false,
        data: {},
      },
      period: "today",
      periods: {
        today: "Today",
        lastweek: "Last week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    // from() {
    //   return 1;
    // },
    // to() {
    //   return this.issues.length;
    // },
    // total() {
    //   return this.issues.length;
    // },
    title() {
      return `${this.pagination.total || 0} Issues`;
    },
    stats() {
      const summary = this.summary.data;
      return [
        {
          label: "Total issues",
          figure: this.formatFigure(summary.total_issues_log),
        },
        {
          label: "Unresolved issues",
          figure: this.formatFigure(summary.total_unresolved_issues_log),
        },
        {
          label: "Resolved issues",
          figure: this.formatFigure(summary.total_resolved_issues_log),
        },

        {
          label: "Resolved Issues within Resolution time",
          figure: this.formatFigure(
            summary.total_issues_resolved_within_resolution_time,
          ),
        },
      ];
    },
    allowSelect() {
      return this.selectedIssues.length;
    },
  },
  watch: {
    page() {
      this.getIssues();
    },
  },
  created() {
    this.initialiseFilters();
    this.getIssuesSummary({ period: this.period });

    // this.getIssues();
  },
  methods: {
    setSelectedIssues(issues) {
      this.selectedIssues = issues.map((issue) => issue.id);
    },
    allowRowSelect() {
      return true;
    },
    issue() {
      this.issueObj = this.issue.filter(
        (issue) => issue.id === parseInt(this.issueId),
      )[0];
    },
    handleIssuesUpdate(item) {
      const { logged_in_userId } = this.$store.getters.user;
      const status = item;
      const issueIds = this.selectedIssues;

      const payload = {
        resolved_by: logged_in_userId,
        issue_ids: issueIds,
        status: status,
      };

      issues
        .updateStatus(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getIssues();
          }
        })
        .catch((error) => {
          console.log(error, "test");
          this.$message.error(error.response?.data.message);
        });
    },
    add() {
      this.showIssueForm = true;
    },
    issuesCategories() {
      this.$router.push({ name: `feedback.issues.categories` });
    },
    search(query) {
      this.loading = true;
      let searchQuery = encodeURI("?title=" + query);

      issues
        .issuesTracker(searchQuery)
        .then((response) => {
          this.loading = false;
          const { status, meta, data } = response.data;
          if (status) {
            this.departmentFilter = null;
            this.statusFilter = null;
            this.orderTypeFilter = null;

            this.filterParams = {
              clear: false,
              status: false,
              params: {},
              paramsLabel: {},
            };

            this.issues = data;
            this.pagination.from = meta.from;
            this.pagination.to = meta.to;
            this.pagination.total = meta.total;
            this.pagination.pageSize = meta.per_page;
            this.showPagination = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.issues = this.sortList(this.issues, property, direction);
    },
    getIssuesSummary({ period, from, to }) {
      this.summary.loading = true;

      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      issues
        .summary(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.summary.data = data;
            this.period = period;
          }
          this.summary.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.departmentFilter = null;
      this.statusFilter = null;
      this.orderTypeFilter = null;

      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getIssues();
    },
    filter({ params, paramsLabel }) {
      var stringedParams;
      if (paramsLabel.affected_user_id) {
        stringedParams =
          "?affected_user_id[]=" + params?.affected_user_id + "&page=1";
      } else {
        stringedParams = "?" + this.sortObjectAsParams(params);
      }

      this.page = 1;
      this.filterParams = {
        status: true,
        params: { ...this.filterParams.params, ...params },
        paramsLabel: { ...this.filterParams.paramsLabel, ...paramsLabel },
      };
      this.getIssues(stringedParams);
    },
    filterOrderTypes(filter) {
      if (filter) {
        this.filter({
          params: { ...this.filterParams.params, order_type: filter },
          paramsLabel: {
            ...this.filterParams.paramsLabel,
            order_type: {
              key: "order_type",
              label: "Order Type",
              type: "list",
              options: this.orderTypes,
              value: [filter],
            },
          },
        });
      } else {
        this.clearFilter("order_type");
      }
    },
    filterIssueStatus(filter) {
      if (filter) {
        this.filter({
          params: { ...this.filterParams.params, status: filter },
          paramsLabel: {
            ...this.filterParams.paramsLabel,
            status: {
              key: "status",
              label: "Status",
              type: "list",
              options: this.issueStatuses,
              value: [filter],
            },
          },
        });
      } else {
        this.clearFilter("status");
      }
    },
    getIssues(params = "") {
      this.loading = true;
      const stringedParams = this.sortObjectAsParams(this.filterParams.params);
      this.stringedParams = stringedParams;
      const paramsPage =
        this.filterParams.params?.affected_user_id ||
        this.filterParams.params?.issue_category
          ? params
          : params + `?page=${this.page}&${this.stringedParams}`;
      issues
        .issuesTracker(paramsPage)
        .then((response) => {
          this.loading = false;
          const { status, meta, data } = response.data;
          if (status) {
            this.issues = data;
            this.pagination.from = meta.from;
            this.pagination.to = meta.to;
            this.pagination.total = meta.total;
            this.pagination.pageSize = meta.per_page;
            // this.showPagination = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    initialiseFilters() {
      const userRole = localStorage.getItem("eden-crm-user-role");
      const filterParams = {
        params: {
          // status: "ongoing",
        },
        paramsLabel: {
          // status: {
          //   key: "status",
          //   label: "Status",
          //   type: "list",
          //   options: this.issueStatuses,
          //   value: ["ongoing"],
          // },
        },
      };

      switch (userRole) {
        case "superadmin":
          this.departmentFilter = "Engineering";
          break;
        case "ofa":
          this.departmentFilter = "OFA";
          break;
        default:
          this.departmentFilter = null;
          break;
      }

      if (this.departmentFilter) {
        filterParams.params = {
          ...filterParams.params,
          department: this.departmentFilter,
        };
        filterParams.paramsLabel = {
          ...filterParams.paramsLabel,
          department: {
            key: "department",
            label: "Department",
            type: "list",
            options: [],
            value: [this.departmentFilter],
            searchable: true,
          },
        };
      }

      this.filter(filterParams);
    },
    exportAction() {
      // if (this.showPagination) {
      //   this.exportData = this.issues;
      // } else {
      //   this.exportData = [];
      // }
      this.showExportIssues = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.issue-categories {
  margin: 0;
  padding-left: 10px;
  list-style-type: circle;
  word-break: break-word;

  li {
    max-width: 250px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  span {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
