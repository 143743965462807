<template>
  <div>
    <el-dialog
      title="Export Issues"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Proceed to select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="loading || exporting"
            @click="exportIssues"
            :disabled="!form.format"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>
  
  <script>
  import issues from "@/requests/feedback/issues";
export default {
  name: "IssuesExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataToExport: {
      type: Array,
      default() {
        return [];
      },
    },
    stringedFilter: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        format: "",
      },
      exportData: {},
      exporting: false,
      loading: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.format = "";
      this.shouldShow = false;
      this.exporting = false;
    },
    exportIssues() {
      this.mixpanelTrack({ event: "issues_export" });
      this.loading = true;
      issues
        .export(this.stringedFilter)
        .then((response) => {
          this.formatExportData(response.data.data);
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response?.data.day;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.loading = false;
        });
    },
    formatExportData(data) {
      console.log(data);
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `Issues \nExported at - ${date}`;
      let issueData = [];
      let headers = {
        sn: "S/N",
        title: "Issues",
        issue_category: "Category",
        status: "Status",
        services: "Services",
        order_type: "Order Type",
        description: "Description",
        affected_user: "Affected User",
        reporter: "Reported By",
        resolved: "Resolved By",
        resolution: "Resolution",
        resolvers: "Resolvers",
        created_at: "Date Created",
        resolved_time: "Resolved Time",
      };
      issueData = data.map((issue, index) => {
        console.log(issue)
        return {
          sn: index + 1,
          title: this.formatText(issue.title),
          issue_category: issue.categories.length ? issue?.categories
                .map((category) => {
                  return `${this.formatText(category?.name)}`;
                })
                .toString()
            : [],
          status: this.formatText(issue.status),
          services: issue.issue_service
            ? this.formatToTitleCase(issue.issue_service.split(",").join(", "))
            : "",
          order_type: this.formatText(
            issue.order_type === "one-time-order" ? "One-time" : "Subscription",
          ),
          description: this.formatText(issue.description),
          affected_user: issue.affected_user?.name.length ? this.formatToTitleCase(issue.affected_user?.name) : "-",
          reporter: issue.reporter?.name.length ? issue.reporter?.name : "-",
          resolved: issue.resolved_by?.name.length ? issue.resolved_by?.name : "-",
          resolution: this.formatText(issue?.resolution),
          resolvers: issue.resolvers?.length
            ? issue?.resolvers
                .map((resolver) => {
                  return `${this.formatText(resolver?.name)}`;
                })
                .toString()
            : [],
          created_at: this.formatDate(issue.created_at, "m do, y"),
          resolved_time: this.formatText(issue.resolved_time),
        };
      });

      this.exportData = {
        title,
        headers,
        data: issueData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
  <style lang="scss" scoped></style>
  